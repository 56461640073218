import React from 'react';
import { PropTypes } from 'prop-types';

import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from 'constants';
import { restrictedBenchmarks } from "../../../utils/constants";
import AllFundsandEtfTab from './allFundsandEtfTab';
import BenchmarksTab from './benchmarksTab';
import Tabs from '../../common/subcomponents/Tabs';
import BenchmarksResults from "../../../components/portfolio/selectPortfolioFund/subComponents/benchmarksResults";
import Hypotheticals from '../../SelectHypotheticals/container'

export default class RightRail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//Tabs
			tabsList: [
				'Funds & ETFs',
				'Benchmarks'
			],
			selectedTab: 'Funds & ETFs',
			selectedTabForScreenerData: '',
			_benchmarkResults: [],
			screenerMasterDataMFSFund: {},
			screenerMasterDataMutualFund: {},
			screenerMasterDataETF: {},
			fetchETFData: false
		};
	}
	updateItem(item){
		this.setState({
			_benchmarkResults : item
	   })
	 }
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.benchmarksResults !== this.props.benchmarksResults &&
			nextProps.benchmarksResults.benchmarks) {	
				if(nextProps.isInternationalUser) {
					nextProps.benchmarksResults.benchmarks = nextProps.benchmarksResults.benchmarks.filter(x =>  restrictedBenchmarks.indexOf(x.name) === -1)
				}
				this.setState({_benchmarkResults: nextProps.benchmarksResults},
				function(){
					this.updateItem(nextProps.benchmarksResults);
				}.bind(this));	
		}
		if (nextProps.screenerMasterData !== this.props.screenerMasterData && nextProps.screenerMasterData) {
			this.setState({
				screenerMasterDataMutualFund:
					this.state.selectedTab === 'Funds & ETFs' ? nextProps.screenerMasterData : this.state.screenerMasterDataMutualFund
			});
		}
		if (nextProps.portfolioBenchmarksResults) {
			this.setState({
				_benchmarkResults: nextProps.portfolioBenchmarksResults},
				function(){
					this.updateItem(nextProps.benchmarksResults);			 	
				}.bind(this));			
		}
	}

	loadTabUI = (e) => {
		if (this.state.selectedTab !== e.target.innerText) {
			this.setState({
				selectedTab: e.target.innerText
			});
			if (this.state.selectedTab !== 'Benchmarks') {
				// hack is used to set focus
				window.setTimeout(() => {
					document.getElementById('search-benchmark').focus();
				}, 0);
			}
		}
	}

	componentDidMount() {
		if(this.props.isInternationalUser) {
			this.props.requestGetScreenerMasterDataApi('MSDBaseCurrencyID|MSDMorningstarCategoryName|MSDBrandingName|MSDKIIDOngoingCharge', 'mutualfund', 'yes');
		} else {
			this.props.requestGetScreenerMasterDataApi('MSDMorningstarOverallRating|MSDMorningstarCategoryName|MSDFundFamilyName', 'mutualfund', 'no');
		}
		if(this.props.isPortfolioCompare) {
			this.props.hypoInitAlloc(this);
		}
	}

	componentWillUnmount() {
		if(this.props.isPortfolioCompare) {
			this.props.hypoInitAlloc(undefined);
		}
  }

	setSelectedTabForScreenerData = (tab) => {
		this.setState({ selectedTabForScreenerData: tab, _screenerData: [] });
	}
	
	childUpdateEventsInitAlloc() {
    this.childHypoInitAlloc.updateEventsInitAlloc();
	}

	render() {
		
		const data = this.props.screenerData;

		return (
			<div>

				{/* Tabs */}
				{!this.props.isPortfolio ?
					<Tabs tabsList={this.state.tabsList} onClick={this.loadTabUI} selectedTab={this.state.selectedTab} />
					: <span></span>}

				{/* Funds Table */}
				{!(this.props.isShowBenchmarkResults || this.props.showHypotheticals) ?
					<AllFundsandEtfTab screenerData={data} screenerMasterData={this.state.screenerMasterDataMutualFund} searchResults={this.props.searchResults} wsodIssue={this.props.wsodIssue} toggleCompareButton={this.props.toggleCompareButton} requestSymbolSearchApi={this.props.requestSymbolSearchApi} requestDecodeApi={this.props.requestDecodeApi} selectedTab={this.state.selectedTab}
						requestScreenerDataApi={this.props.requestScreenerDataApi}
						requestScreenerFiltersDataApi={this.props.requestScreenerFiltersDataApi}
						removeFund={this.props.removeFund}
						pushFund={this.props.pushFund}
						selectedTabForScreenerData={this.state.selectedTabForScreenerData}
						setSelectedTabForScreenerData={this.setSelectedTabForScreenerData}
						isSelected={this.state.selectedTab === 'Funds & ETFs'}
						screenerFiltersOutput={this.props.screenerFiltersOutput}
						resetScreenerFiltersOutput={this.props.resetScreenerFiltersOutput}
						isPortfolio={this.props.isPortfolio}
						isPortfolioCompare={this.props.isPortfolioCompare}
						addFundsToFundResultsTable={this.props.addFundsToFundResultsTable}
						addAllFunds={this.props.addAllFunds}
						pills={this.props.pills}
						showAddAllTooltipP1={this.props.showAddAllTooltipP1}
						showAddAllTooltipP2={this.props.showAddAllTooltipP2}
						closeTooltip={this.props.closeTooltip}
						portfolioNamesList={this.props.portfolioNamesList}
						getTemplateForSelectedPortfolio={this.props.getTemplateForSelectedPortfolio}
						defaultSelectedPortfolioOneText={this.props.defaultSelectedPortfolioOneText}
						defaultSelectedPortfolioTwoText={this.props.defaultSelectedPortfolioTwoText}
						pushAllFund={this.props.pushAllFund}
						onChangeIncludeCashInPortfolios={this.props.onChangeIncludeCashInPortfolios}
						onRemoveCashInPortfolios={this.props.onRemoveCashInPortfolios}
						isIncludeCashInPortfolios={this.props.isIncludeCashInPortfolios}
						calledFromFundSearchbar={this.props.calledFromFundSearchbar}
						userType={this.props.userType}
						isInternationalUser={this.props.isInternationalUser}
						currency={this.props.currency}
						requestGetScreenerCategoryDataApi = {this.props.requestGetScreenerCategoryDataApi}
						screenerDataMstarCategory = {this.props.screenerDataMstarCategory}
					/> : ''
				}

				{/* Benchmark Tabs */}
				{!this.props.isPortfolio ?
					<BenchmarksTab benchmarksResults={this.state._benchmarkResults}
						requestGetBenchmarksApi={this.props.requestGetBenchmarksApi}
						toggleCompareButton={this.props.toggleCompareButton}
						fundInFocus={this.props.fundInFocus}
						primaryBenchmarksResult={this.props.primaryBenchmarksResult}
						requestGetPrimaryBenchmarkApi={this.props.requestGetPrimaryBenchmarkApi}
						getPrimaryProspectusBenchmarkData={this.props.getPrimaryProspectusBenchmarkData}
						isSelected={this.state.selectedTab === 'Benchmarks'}
						currency={this.props.currency}
						isInternationalUser={this.props.isInternationalUser}
					/>
					: <span></span>}

				{/* PvP Comparison: Benchmark Results */}
				{this.props.isPortfolioCompare && this.props.isShowBenchmarkResults ?
					<BenchmarksResults
						toggleCompareButton={this.props.toggleCompareButton}
						requestGetBenchmarksApi={this.props.requestGetBenchmarksApi}
						benchmarksResults={this.state._benchmarkResults}
						currency={this.props.currency}
						isInternationalUser={this.props.isInternationalUser}
					/> :
					<span></span>}
				{this.props.isPortfolioCompare && this.props.showHypotheticals && this.props.hypotheticalState.periodList &&
					<Hypotheticals 
						hypotheticalState={this.props.hypotheticalState}
						bindPeriodStartDate={this.props.bindPeriodStartDate}
						updateHypothicalStateTotalInvestment={this.props.updateHypothicalStateTotalInvestment}
						updateHypothicalStateFundValuesPercents={this.props.updateHypothicalStateFundValuesPercents}
						updateInvestments={this.props.updateInvestments}
						hideBenchmarkAndHypotheticalsResults={this.props.hideBenchmarkAndHypotheticalsResults}
						updateUnavailableFunds={this.props.updateUnavailableFunds}
						bindPeriodDropDown = {this.props.bindPeriodDropDown}
						workingCopyConatainerIds = {this.props.workingCopyConatainerIds}						
						comingFromHypotheticals = {this.props.comingFromHypotheticals}						
						validateInitialInvestment={this.props.validateInitialInvestment}
						eventsValidate={this.props.eventsValidate}
						setValidationModalTabs={this.props.setValidationModalTabs}
						firstTimeToHypotheticals={this.props.firstTimeToHypotheticals}
						isFirstTimeToHypotheticals = {this.props.isFirstTimeToHypotheticals}
						validateSameDayEvent = {this.props.validateSameDayEvent}
						isFromModuleSelection={this.props.isFromModuleSelection}
						indexInception= {this.props.indexInception}
						updateHypothicalStateMaintainInitialAlloc={this.props.updateHypothicalStateMaintainInitialAlloc}
						hypoInitAlloc={ref => (this.childHypoInitAlloc = ref)}
						setComparisonInvestmentDetails={this.props.setComparisonInvestmentDetails}
						comparisonPortfolioInvestments={this.props.comparisonPortfolioInvestments}
						currencySymbol={this.props.currencySymbol}
						/>
				}
			</div>
		);
	}
}

RightRail.PropTypes = {
	toggleCompareButton: PropTypes.func,
	requestSymbolSearchApi: PropTypes.func,
	requestDecodeApi: PropTypes.func,
	screenerData: PropTypes.Array,
	screenerFiltersOutput: PropTypes.Array,
	requestGetBenchmarksApi: PropTypes.func,
	requestGetPrimaryBenchmarkApi: PropTypes.func,
	getPrimaryProspectusBenchmarkData: PropTypes.func,
	requestScreenerDataApi: PropTypes.func,
	requestScreenerFiltersDataApi: PropTypes.func,
	requestGetScreenerMasterDataApi: PropTypes.func,
	resetScreenerFiltersOutput: PropTypes.func,
	isPortfolio: PropTypes.bool,
	addFundsToFundResultsTable: PropTypes.func,
	addAllFunds: PropTypes.func,
	pills: PropTypes.Array,
	isPortfolioCompare: PropTypes.bool,
	portfolioNamesList: PropTypes.object,
	getTemplateForSelectedPortfolio: PropTypes.func,
	requestGetBenchmarksApi: PropTypes.func,
	hypotheticalState: PropTypes.object,
	bindPeriodStartDate: PropTypes.func,
	bindPeriodDropDown: PropTypes.func,
	updateInvestments: PropTypes.func, 
	bindPeriodStartDate: PropTypes.func,	
	comingFromHypotheticals: PropTypes.func,	
	validateInitialInvestment: PropTypes.func,
	eventsValidate: PropTypes.func,
	setValidationModalTabs: PropTypes.func,
	isFirstTimeToHypotheticals: PropTypes.bool,
	validateSameDayEvent: PropTypes.func,
	requestGetScreenerCategoryDataApi: PropTypes.func,
	screenerDataMstarCategory: PropTypes.object
};
