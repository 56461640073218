import React from 'react';
import { PropTypes } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';

import * as Constant from '../../../utils/constants';
import * as CustomModalStyles from '../../../utils/customModalStyles';

import FundResultsTable from './fundResultsTable';
import FundSearchInput from './fundSearchInput';
import ResultHeader from './resultHeader';
import ScreenerFlyout from './ScreenerFlyout';
import ScreenerFlyoutInternational from './ScreenerFlyoutInternational';

import ResultSetTooLargeIcon from '../../common/Icons/ResultSetTooLargeIcon';
import ScreenerIcon from '../../common/Icons/ScreenerIcon';
import { removeDuplicateItemsInArray } from "../../../utils/commonMethods";
export default class AllFundsandEtfTab extends React.Component {

	refSetTimeout = null;

	constructor(props) {
		super(props);
		this.state = {
			searchItems: [],
			selected: [],
			reloadTable: false,
			combinedResults: [
				{
					options: {
						name: '',
						symbol: '',
						xid: '',
						region: ''
					}
				}
			],
			clearSearchInput: false,
			openScreenerFlyout: false,
			removedFunds: [],
			allFundsRemoved: []
		};
	}

	componentDidMount() {
		this.removeNotification();
		if (this.props.pills && this.props.pills.length > 0 && this.props.isPortfolioCompare) {
			this.setState({ selected: this.props.pills })
		}
	}

	componentWillUnmount() {
		clearTimeout(this.refSetTimeout);
	}

	filterSearchItems(searchResults) {
		const fundsCount =
			searchResults.openfundedResults !== null && Array.isArray(searchResults.openfundedResults) ? searchResults.openfundedResults.length : 0;
		const etfCount =
			searchResults.etfResults !== null &&
				Array.isArray(searchResults.etfResults) ? searchResults.etfResults.length : 0;

		/// remove duplicate elements from already selected funds.

		const _selectedfunds =
			this.state.selected && this.state.selected.length > 0 ? this.state.selected.map(x => x.symbol) : [];

		searchResults.openfundedResults = Array.isArray(
			searchResults.openfundedResults
		)
			? searchResults.openfundedResults.filter(
				val => !_selectedfunds.includes(val.mfsSymbol)
			)
			: [];

		searchResults.etfResults = Array.isArray(searchResults.etfResults)
			? searchResults.etfResults.filter(
				val => !_selectedfunds.includes(val.mfsSymbol)
			)
			: [];

		let totalPossibleFundsCount = 0;
		let totalPossibleETFCount = 0;
		const resultSize = 5;

		//Edge Cases START
		if (fundsCount <= resultSize && etfCount <= resultSize) {
			totalPossibleFundsCount = fundsCount;
			totalPossibleETFCount = etfCount;
		} else if (fundsCount >= resultSize && etfCount >= resultSize) {
			totalPossibleFundsCount = resultSize;
			totalPossibleETFCount = resultSize;
		} else if (fundsCount <= resultSize) {		// Conditional Scenarios Start:
			if (etfCount > resultSize) {
				totalPossibleETFCount = resultSize;
				totalPossibleFundsCount = fundsCount;
			}
		} else if (fundsCount >= resultSize) {
			if (etfCount < resultSize) {
				totalPossibleETFCount = etfCount;
				totalPossibleFundsCount = resultSize;
			}
		}

		this.setResultsSizeBasedOnCalculation(
			searchResults,
			totalPossibleFundsCount,
			totalPossibleETFCount
		);
	}

	checkDecodeAPI = (key) => {
		this.props.requestDecodeApi(key);
	}

	resetTab = () => {
		this.setState({
			resetScreenerFilters: true,
			selected: [],
			clearSearchInput: true,
			reloadTable: false
		});
	}

	GetSelectedFunds = (funds) => {
		funds = removeDuplicateItemsInArray(funds, 'symbol');
		if (funds && funds.length > 0) {
			const html = document.getElementsByClassName(
				'rbt-token rbt-token-removeable'
			);
			if (funds.length > this.state.selected.length) {
				this.handleCall();
			}
			//this.props.requestGetScreenerCategoryDataApi(funds[0].xid)
			this.setState({ selected: funds });
		} else {
			if (funds.length === 0 & this.state.selected.length > 0) {
				this.removeAllFunds(true);
			}
			document.getElementsByClassName('rbt-input-main')[0].focus();
			this.setState({ selected: funds });
		}
		this.props.calledFromFundSearchbar(true);
	}

	addSearchedFund = (option) => {
		let selectedTokens = cloneDeep(this.state.selected);
		const value = option.currentTarget;
		let _doesRecordExistsinRecordsTable = false;


		if (option !== undefined) {
			let symbol = option.currentTarget.getAttribute('data-symbol');
			let isActive = option.currentTarget.getAttribute('data-isactive');

			if (isActive == "true") { // if item is already selected..
				let removeIndex = selectedTokens.findIndex(i => {
					return i.symbol === symbol;
				});
				if (removeIndex > -1) {
					selectedTokens.splice(removeIndex, 1);
				}
			} else { //item should be added158

				//check for duplicate records in fund results table. 
				_doesRecordExistsinRecordsTable = this.props.screenerData.some(item => item.MFSSymbol == symbol || item.MFSSymbolISIN == symbol); 

				if (selectedTokens && !_doesRecordExistsinRecordsTable) {
					const symbol_ = option.currentTarget.getAttribute('data-symbol');
					const wsodIssue = option.currentTarget.getAttribute('data-wsodissue');
					const fundName = option.currentTarget.getAttribute('data-fundName');

					let customPill = {
						name: '',
						symbol: '',
						xid: '',
						region: ''
					}
					customPill.name = this.props.isInternationalUser ? fundName : '';
					customPill.symbol = symbol_;
					customPill.wsodIssue = wsodIssue;
					customPill.xid = '';
					selectedTokens.push(customPill);
				}
			}
		}
		if (!_doesRecordExistsinRecordsTable) {
			this.setState({
				selected: selectedTokens,
			}, () => {
				this.props.addFundsToFundResultsTable(value)
			});
		}
	}
	removeAllFunds = () => {
		let seletedTokens = cloneDeep(this.state.selected);
		let allFundsRemoved = cloneDeep(this.state.allFundsRemoved);
		let screenerData = this.props.screenerData;
		let date = new Date();
		let seconds = date.getTime() / 1000;

		for (let i = 0; i < seletedTokens.length; ++i) {
            if ((this.props.isInternationalUser && (seletedTokens[i].symbol === Constant.CustomFunds.Cash.MFSSymbolISIN || Constant.CustomFundXids.includes(seletedTokens[i].xid))) ||
                seletedTokens[i].symbol === Constant.CustomFunds.Cash.MFSSymbol) {
                if ((this.props.isInternationalUser &&
                    (allFundsRemoved.findIndex(fund => { return fund.pillData.symbol === Constant.CustomFunds.Cash.MFSSymbolISIN }) === -1 || allFundsRemoved.findIndex(fund => { return Constant.CustomFundXids.includes(fund.pillData.xid) }) === -1))||
                    (allFundsRemoved.findIndex(fund => { return fund.pillData.symbol === Constant.CustomFunds.Cash.MFSSymbol }) === -1)) {
                    this.props.onRemoveCashInPortfolios();
					allFundsRemoved.push({
						time: seconds,
						pillData: seletedTokens[i],
						resultTable: screenerData[i]
					})
                }
			} else {
				allFundsRemoved.push({
					time: seconds,
					pillData: seletedTokens[i],
					resultTable: screenerData[i]
				})
			}
		}
		seletedTokens = [];

		this.setState({
			selected: seletedTokens, allFundsRemoved: allFundsRemoved
		}, () => {
			(() => this.props.removeFund())()
		});
	}
	removeSearchedFund = (option) => {
		let seletedTokens = cloneDeep(this.state.selected);
		let removedFunds = cloneDeep(this.state.removedFunds);
		let screenerData = this.props.screenerData;
		let seletedSymbol = "";

		let date = new Date();
		let seconds = date.getTime() / 1000;
		let data = {
			time: seconds,
			pillData: [],
			resultTable: []
		}

		if (option !== undefined) {
			seletedSymbol = option.currentTarget.previousSibling.getAttribute('data-symbol');
			let removeIndex = seletedTokens.findIndex(i => {
				return i.symbol === seletedSymbol;
			});
			if (this.props.isInternationalUser && seletedSymbol === Constant.CustomFunds.Cash.MFSSymbolISIN) {
				removeIndex = seletedTokens.findIndex(i => {return (i.symbol === seletedSymbol || i.symbol === Constant.CustomFunds.Cash.MFSSymbol);});
			}
			data.pillData = seletedTokens[removeIndex];

			if (removeIndex > -1) {
				seletedTokens.splice(removeIndex, 1);
            }
            if ((this.props.isInternationalUser && seletedSymbol === Constant.CustomFunds.Cash.MFSSymbolISIN) ||
                seletedSymbol === Constant.CustomFunds.Cash.MFSSymbol) {
                this.props.onRemoveCashInPortfolios();
            }
		} else {
			if (seletedTokens.length > 0) {
				seletedSymbol = seletedTokens[seletedTokens.length - 1].symbol;
				data.pillData = seletedTokens[seletedTokens.length - 1];
				seletedTokens.splice(seletedTokens.length - 1, 1);
			}
        }
        if (this.props.isInternationalUser) {
            let removeIndexScreener = screenerData.findIndex(i => {
                return i.MFSSymbolISIN === seletedSymbol;
            });
            data.resultTable = screenerData[removeIndexScreener];
        } else {
            let removeIndexScreener = screenerData.findIndex(i => {
                return i.MFSSymbol === seletedSymbol;
            });
            data.resultTable = screenerData[removeIndexScreener];
        }
		
		removedFunds.push(data);
		
		this.handleUIIssue();

		this.setState({
			selected: seletedTokens, removedFunds: removedFunds
		}, () => {
			(() => this.props.removeFund(seletedSymbol))()
		});
	}
	// Changes for MFS-5152
	addSuggestedFund = (xid, symbol) => {
		let result = {};
		result.key = xid;
		//Iterate over selected state values
		// Create a new array by mapping over the current state's selected items
		const updatedSelected = this.state.selected.map(item => {
			// Check if the item's symbol matches the given symbol
			if (item.symbol === symbol) {
				// If it matches, update the suggested property to false
				return {
					...item, // Spread the current item properties
					suggested: false // Update the suggested property
				};
			} else {
				// If it doesn't match, return the item unchanged
				return item;
			}
		});

		// Set the state with the updated selected items
		this.setState({ selected: updatedSelected, reloadTable: true });
		this.checkDecodeAPI([result]);
	}
	
	handleCall = (event) => {
		const html = this.props.isInternationalUser ?
			document.getElementsByClassName('data-pill') : document.getElementsByClassName('us-data-pill');

		if (html && html.length > 0) {
			let result = {};

			const c = html[html.length - 1].getAttribute('data-xid');
			result.key = c;
			this.checkDecodeAPI([result]);
			this.setState({ reloadTable: true });
		} else {
			document.getElementsByClassName('rbt-input-main')[0].focus();
		}
		this.props.setSelectedTabForScreenerData(this.props.selectedTab);
	}

	setResultsSizeBasedOnCalculation(
		searchResults,
		totalPossibleFundsCount,
		totalPossibleETFCount
	) {
		const openEndedFunds =
			searchResults.openfundedResults.length > 0 ? searchResults.openfundedResults.slice(0, totalPossibleFundsCount) : [];
		const ETF =
			searchResults.etfResults.length > 0 ? searchResults.etfResults.slice(0, totalPossibleETFCount) : [];

		const totaloptions = [];

		openEndedFunds.map(item => {
			const options = {};
			options.name = item.mfsName === undefined ? '--' : item.mfsName;
			options.symbol = item.mfsSymbol;
			options.xid = item.key;
			options.region = 'Funds';
			totaloptions.push(options);
			return totaloptions;
		});

		ETF.map(item => {
			const options = {};
			options.name = item.mfsName === undefined ? '--' : item.mfsName;
			options.symbol = item.mfsSymbol;
			options.xid = item.key;
			options.region = 'ETFs';
			totaloptions.push(options);
			return totaloptions;
		});

		this.setState(prevState => {
			return { combinedResults: totaloptions };
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.searchResults !== this.props.searchResults &&
			nextProps.searchResults !== null
		) {
			const searchResults = [];
			if (nextProps.searchResults.openfundedResults !== null) {
				searchResults.push(nextProps.searchResults.openfundedResults);
			}
			if (nextProps.searchResults.etfResults !== null) {
				searchResults.push(nextProps.searchResults.etfResults);
			}
			this.setState(prevState => {
				return { searchItems: searchResults };
			});
			this.filterSearchItems(nextProps.searchResults);
		}
		if (this.props.pills !== nextProps.pills && nextProps.pills.length >= 1) {
			this.setState(prevState => {
				return { selected: nextProps.pills };
			});
		}
		if (this.props.screenerDataMstarCategory !== nextProps.screenerDataMstarCategory && this.props.isInternationalUser ) {
			let totaloptions = [];
				if(this.props.isInternationalUser){
				  if (Array.isArray(nextProps.screenerDataMstarCategory?.topFiveFundsBasedOnNetAsset)) {
				    nextProps.screenerDataMstarCategory?.topFiveFundsBasedOnNetAsset.map(item => {
				    	let options = {};
				    	if (!item) {
				    		options = null;
				    	} else {
				    	   if (item.MSDFundName) {
				    	   	 options.name = item.MSDFundName;
				    	   }
				    	   if (item.MFSSymbolISIN) {
				    	   	 options.symbol = item.MFSSymbolISIN;
				    	   }
				    	   if (item.MSDFundShareClassID) {
				    	   	 options.xid = item.MSDFundShareClassID;
				    	   }
				    	   options.isInternationalUser = true;
				    	   options.suggested = true;
				    	   totaloptions.push(options);
				    	}
				    	return totaloptions;
				    });
				  }
				// Initialize an array to store selected items
				let selectedItems = [];

				// Iterate over totaloptions array and check if each item is not already selected
				for (const option of totaloptions) {
					// Check if the option is not present in this.state.selected
					if (!this.state.selected.some(selectedItem => selectedItem.name === option.name)) {
						// If not present, add it to the selectedItems array
						selectedItems.push(option);
						// If selectedItems array has reached the limit of 5 items, break the loop
						if (selectedItems.length === 5) {
							break;
						}
					}
				}
				selectedItems = [...this.state.selected, ...selectedItems];
				this.setState ({selected: selectedItems});
			}
		}
	}

	actualSearch = (event) => {
		this.setState(prevState => {
			return { clearSearchInput: false };
		});
	}

	closeScreenerFlyout = () => {
		this.setState({
			openScreenerFlyout: false,
		}, () => {
			(() => this.props.resetScreenerFiltersOutput())()
		});
	}

	openScreenerFlyout = () => {
		this.removeUndoNotifications()
		this.setState({
			openScreenerFlyout: true
		}, () => {
			(() => this.props.resetScreenerFiltersOutput())()
		});
	}

	removeUndoNotifications = () => {
		this.setState({
			removedFunds: [],
			allFundsRemoved: []
		})
	}
	undoNotificationAll = () => {
		let allFundsRemoved = cloneDeep(this.state.allFundsRemoved);
		let selected = cloneDeep(this.state.selected);
		let screenerData = this.props.screenerData;
		let oldResultTableData = [];
		for (let i = 0; i < allFundsRemoved.length; ++i) {
			let _recordIndex = -1;
			_recordIndex = screenerData.findIndex(
				x => x.MSDWSODIssue == allFundsRemoved[i].resultTable.MSDWSODIssue
			);
			if (_recordIndex < 0) {
				selected.push(allFundsRemoved[i].pillData);
				oldResultTableData.push(allFundsRemoved[i].resultTable)
			} else {
				oldResultTableData.push(allFundsRemoved[i].resultTable)
			}
			allFundsRemoved.splice(i--, 1);
		}
		this.setState({ 
			allFundsRemoved: allFundsRemoved
		}, () => {
			(() => this.props.pushAllFund(screenerData,oldResultTableData, this.props.isInternationalUser))()
		});

	}
	undoNotification = (e) => {
		let removedFunds = cloneDeep(this.state.removedFunds);
		let selected = cloneDeep(this.state.selected);
		let symbol = e.currentTarget.getAttribute('data-symbol');
		let screenerData = this.props.screenerData;
		let oldResultTableData = [];
		let selectedindex = selected.findIndex(i => {
			return i.symbol === symbol;
		});
		if (selectedindex < 0) {
			let removeIndex = removedFunds.findIndex(i => {
				return i.pillData.symbol === symbol;
			});
			let fundData = cloneDeep(removedFunds[removeIndex]);
			removedFunds.splice(removeIndex, 1);

			selected.push(fundData.pillData);
			screenerData.push(fundData.resultTable)
			oldResultTableData.push(fundData.resultTable)
			this.setState({
				removedFunds: removedFunds, selected: selected
			}, () => {
				(() => this.props.pushFund(screenerData, oldResultTableData))()
			});
		} else {
			let removeIndex = removedFunds.findIndex(i => {
				return i.pillData.symbol === symbol;
			});
			let fundData = cloneDeep(removedFunds[removeIndex]);
			removedFunds.splice(removeIndex, 1);
			let _recordIndex = -1;
			_recordIndex = screenerData.findIndex(
				x => x.MSDWSODIssue == fundData.resultTable.MSDWSODIssue
			);
			if (_recordIndex < 0) {
				screenerData.push(fundData.resultTable)
				oldResultTableData.push(fundData.resultTable)
			} else {
				screenerData[_recordIndex].isActive = fundData.resultTable.isActive ? fundData.resultTable.isActive : screenerData[_recordIndex].isActive
				screenerData[_recordIndex].isActiveP2 = fundData.resultTable.isActiveP2 ? fundData.resultTable.isActiveP2 : screenerData[_recordIndex].isActiveP2
			}
			this.props.pushFund(screenerData, oldResultTableData)
		}
	}

	removeNotification = () => {
		let date = new Date();
		let seconds = date.getTime() / 1000;
		let removedFunds = cloneDeep(this.state.removedFunds);
		let allFundsRemoved = cloneDeep(this.state.allFundsRemoved);

		for (let i = 0; i < removedFunds.length; ++i) {
			if (seconds > (removedFunds[i].time + 5)) {
				removedFunds.splice(i--, 1);
			}
		}
		for (let i = 0; i < allFundsRemoved.length; ++i) {
			if (seconds > (allFundsRemoved[i].time + 5)) {
				allFundsRemoved.splice(i--, 1);
			}
		}

		if (this.state.removedFunds.length !== removedFunds.length || 
			this.state.allFundsRemoved.length !== allFundsRemoved.length) {
			this.setState({ removedFunds: removedFunds, allFundsRemoved: allFundsRemoved });	
		}

		this.refSetTimeout = setTimeout(() => {
			this.removeNotification();
		}, 1000)
	}

	toggleCompareButton = (e) => {
		this.setState({
			removedFunds: [],
			allFundsRemoved: []
		})
		this.props.toggleCompareButton(e);
	}

	addAllFunds = (index) => {
		this.setState({
			removedFunds: [],
			allFundsRemoved: []
		})
		this.props.addAllFunds(index);
	}

	handleUIIssue = () => {
		let elemChild = document.querySelector('.fundSearchInput .rbt.clearfix.open.has-aux div:nth-child(4) .rbt-menu.dropdown-menu.show');
		if (elemChild) {
			elemChild.style.position = 'absolute';
			elemChild.style.transform = 'unset';
			elemChild.style.top = 'auto';
		}
	}

	onChangeIncludeCashInPortfolios = (evt) => {
		this.removeUndoNotifications();
		let _selected = this.state.selected;
		let isChecked = evt.target.checked;

		_selected = _selected.filter((sel)=> {return sel.symbol !== Constant.CustomFunds.Cash.MFSSymbol});

		if (isChecked) {
			if (this.props.isInternationalUser) {
				let cash = {};
				switch (this.props.currency) {
					case 'USD':
						cash = Constant.CustomFunds.Cash;
						break;
					case 'CHF':
						cash = Constant.CustomFunds.CashCHF;
						break;
					case 'EUR':
						cash = Constant.CustomFunds.CashEUR;
						break;
					case 'GBP':
						cash = Constant.CustomFunds.CashGBP;
						break;
				}
				_selected.push({
					xid: cash.MSDWSODIssue, 
					symbol: cash.MFSSymbol,
					name: cash.MSDFundName
				});
			} else {
				_selected.push({
					xid: Constant.CustomFunds.Cash.MSDWSODIssue, 
					symbol: Constant.CustomFunds.Cash.MFSSymbol
				});
			}
		}

		this.setState({ selected: _selected });

		if (_selected && _selected.length > 0) {
			if (_selected.length > this.state.selected.length) {
				this.handleCall();
			}
		} else {
			document.getElementsByClassName('rbt-input-main')[0].focus();
			this.setState({ selected: _selected });
		}

		this.props.onChangeIncludeCashInPortfolios(isChecked);
	}
	
	render() {
		let cssClass = '';

		if (this.props.isSelected) {
			cssClass = 'display';
		} else {
			cssClass = 'no-display';
		}

		return (
			<div className={cssClass}>
				{this.props.isInternationalUser ? 
				<ScreenerFlyoutInternational
					openScreenerFlyout={this.state.openScreenerFlyout}
					closeScreenerFlyout={this.closeScreenerFlyout}
					customStyles={CustomModalStyles.ScreenerFlyoutModalStyles}
					screenerMasterData={this.props.screenerMasterData}
					requestScreenerFiltersDataApi={this.props.requestScreenerFiltersDataApi}

					selectedTab={this.props.selectedTab}
					selectedTabForScreenerData={this.props.selectedTabForScreenerData}
					setSelectedTabForScreenerData={this.props.setSelectedTabForScreenerData}
					screenerFiltersOutput={this.props.screenerFiltersOutput}
					resetScreenerFiltersOutput={this.props.resetScreenerFiltersOutput}
					addFundsToFundResultsTable={this.addSearchedFund}
					isPortfolio={this.props.isPortfolio}
					isPortfolioCompare={this.props.isPortfolioCompare}
				/>
				:
				<ScreenerFlyout
					openScreenerFlyout={this.state.openScreenerFlyout}
					closeScreenerFlyout={this.closeScreenerFlyout}
					customStyles={CustomModalStyles.ScreenerFlyoutModalStyles}
					screenerMasterData={this.props.screenerMasterData}
					requestScreenerFiltersDataApi={this.props.requestScreenerFiltersDataApi}

					selectedTab={this.props.selectedTab}
					selectedTabForScreenerData={this.props.selectedTabForScreenerData}
					setSelectedTabForScreenerData={this.props.setSelectedTabForScreenerData}
					screenerFiltersOutput={this.props.screenerFiltersOutput}
					resetScreenerFiltersOutput={this.props.resetScreenerFiltersOutput}
					addFundsToFundResultsTable={this.addSearchedFund}
					isPortfolio={this.props.isPortfolio}
					isPortfolioCompare={this.props.isPortfolioCompare}
				/>
				}

				<div className={'searchbox'}>
					<span className={'searchIcon'} />
					<div
						className={'searchText'}
					>
						<FundSearchInput
							fundtype="MVP"
							options={this.state.combinedResults}
							GetSelectedFunds={this.GetSelectedFunds}
							actualSearch={this.actualSearch}
							requestSymbolSearchApi={this.props.requestSymbolSearchApi}
							clearSearchInput={this.state.clearSearchInput}
							removeSearchedFund={this.removeSearchedFund}
							selected={this.state.selected}
							isPortfolio={this.props.isPortfolio}
							isPortfolioCompare={this.props.isPortfolioCompare}
							removeUndoNotifications = {this.removeUndoNotifications}
							isInternationalUser={this.props.isInternationalUser}
							addSuggestedFund = {this.addSuggestedFund}
						/>
					</div>
					<div
						onClick={this.openScreenerFlyout}
						className={'screener-button'}
					>
						<ScreenerIcon />
					</div>

				</div>
				<div>
					<ResultHeader rowCount={this.state.resetScreenerFilters ? '0' : this.props.screenerData.length}
						resetTab={this.resetTab}
						showResetButton={false}
						componentType={'SelectFundModal'}
						isPortfolio={this.props.isPortfolio}
						isPortfolioCompare={this.props.isPortfolioCompare}
						onChangeIncludeCashInPortfolios={this.onChangeIncludeCashInPortfolios}
						isIncludeCashInPortfolios={this.props.isIncludeCashInPortfolios}
					/>
					<FundResultsTable
						fundtype="Funds & ETFs"
						removeSearchedFund={this.removeSearchedFund}
						toggleCompareButton={this.toggleCompareButton}
						screenerData={this.state.resetScreenerFilters ? [] : this.props.screenerData}
						resetScreenerFilters={this.state.resetScreenerFilters}
						isPortfolio={this.props.isPortfolio}
						isPortfolioCompare={this.props.isPortfolioCompare}
						addAllFunds={this.addAllFunds}
						showAddAllTooltipP1={this.props.showAddAllTooltipP1}
						showAddAllTooltipP2={this.props.showAddAllTooltipP2}
						closeTooltip={this.props.closeTooltip}
						showAddAllTooltip={this.props.showAddAllTooltip}
						portfolioNamesList={this.props.portfolioNamesList}
						getTemplateForSelectedPortfolio={this.props.getTemplateForSelectedPortfolio}
						defaultSelectedPortfolioOneText={this.props.defaultSelectedPortfolioOneText}
						defaultSelectedPortfolioTwoText={this.props.defaultSelectedPortfolioTwoText}
						removeUndoNotifications = {this.removeUndoNotifications}
						isInternationalUser={this.props.isInternationalUser}
					/>
				</div>

				<div className={'notification-div'} id="notification-app" style={{ width: this.state.removedFunds.length > 6 ? '1700px' : 'auto' }}>
					{this.props.isPortfolio ? this.state.removedFunds.map(funds => {
						return (
							funds.pillData !== undefined ? <div className={'notification'}>
								<div className={'message'}>You have cleared {this.props.isInternationalUser ? funds.pillData.name : funds.pillData.symbol} from your comparison</div>
								<div className={'undo-link'}><a src="#" onClick={this.undoNotification} data-symbol={funds.pillData.symbol}>Undo</a></div>
							</div> : ''
						)
					}
					) : ''}
					{this.props.isPortfolio ? (this.state.allFundsRemoved.length > 0 ?
						<div className={'notification'}>
							<div className={'message'}>You have cleared all funds from your comparison</div>
							<div className={'undo-link'}><a src="#" onClick={this.undoNotificationAll}>Undo</a></div>
						</div> : '') : ''
					}

				</div>

			</div>
		);
	}
}

AllFundsandEtfTab.PropTypes = {
	requestSymbolSearchApi: PropTypes.func,
	requestDecodeApi: PropTypes.func,
	screenerData: PropTypes.Array,
	requestScreenerFiltersDataApi: PropTypes.func,
	resetScreenerFiltersOutput: PropTypes.func,
	pushFund: PropTypes.func,
	isPortfolio: PropTypes.bool,
	addFundsToFundResultsTable: PropTypes.func,
	isPortfolioCompare: PropTypes.bool,
	addAllFunds: PropTypes.func,
	closeTooltip: PropTypes.func,
	isPortfolioCompare: PropTypes.bool,
	portfolioNamesList: PropTypes.object,
	getTemplateForSelectedPortfolio: PropTypes.func,
	toggleCompareButton: PropTypes.func,
	handleUIIssue: PropTypes.func,
	requestGetScreenerCategoryDataApi: PropTypes.func,
	screenerDataMstarCategory: PropTypes.object
};
 