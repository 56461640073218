import { createStore, applyMiddleware, compose } from 'redux';

import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducers';
import rootSaga from '../sagas/initializer';

export default function configureStore(initialState={}) {
	const sagaMiddleware = createSagaMiddleware();
	const middleware = applyMiddleware(sagaMiddleware);
	const enhancer = compose(middleware);
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(rootReducer, initialState, composeEnhancers(enhancer));

	// run sagas
	sagaMiddleware.run(rootSaga);

	return store;
}
